<!-- 商品详情 -->
<template>
  <div id="details">
    <!-- 头部 -->
    <div class="page-header">
      <div class="fillDiv">&nbsp;</div>
      <div class="title">
        <span><a href="/" target="_self">首页</a></span
        ><code>></code>
        <span
          ><a href="/goods" target="_self" style="color: #666"
            >毛绒玩具</a
          ></span
        ><code>></code> {{ goodsInfo.PName }}
      </div>
    </div>
    <!-- 头部END -->
    <!-- 主要内容 -->
    <div class="main">
      <!-- 商品主要参数 -->
      <div class="goodsInfo">
        <!-- 左侧商品轮播图 -->
        <div class="goodsImgs">
          <div class="goodsCover">
            <image-zoomer
              :src="goodsCover"
              :width="400"
              :height="400"
              :zoomRatio="2"
            ></image-zoomer>
          </div>
          <!-- 视频图层叠加 -->
          <div
            style="position: absolute; top: 256px; height: 400px; width: 402px"
            v-if="video && this.activeImgIndex === 0"
          >
            <video-player
              id="videoPlayer"
              class="video-player vjs-custom-skin middle-video"
              ref="videoPlayer"
              :playsinline="true"
              :options="videoGoodOption"
            >
            </video-player>
          </div>
          <div class="goodsImgList">
            <swiper ref="mySwiper" :options="swiperOption">
              <swiper-slide
                class="recommend-item"
                v-for="(item, i) of goodsPicture"
                :key="i"
              >
                <el-image
                  :src="item.file"
                  style="width: 51px; height: 51px"
                  :class="activeImgIndex === i ? 'is_active' : ''"
                  @click="changeGoodsCover(i)"
                ></el-image>
                <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div> -->
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <!-- 左侧商品轮播图END -->
        <!-- 中间规格区 -->
        <div class="goodsSku">
          <el-row>
            <el-col :span="12">
              <span class="goodsName">{{ goodsInfo.PName }}</span>
              <span class="daifa" v-if="goodsInfo.skuFlag > 0">代</span>
            </el-col>
            <el-col :span="12" style="text-align: right; padding-right: 10px">
              <span class="goodsAction" @click="addCollect"
                ><i class="el-icon-star-off"></i>收藏商品</span
              >
              <span class="separator">|</span>
              <span class="goodsAction" @click="searchTheSame"
                ><i class="el-icon-search"></i>找同款</span
              >
              <span class="separator">|</span>
              <span class="goodsAction" @click="searchPName"
                ><i class="el-icon-search"></i>搜标题</span
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="goodsPrice"
              ><label>批发价:</label> {{ goodsInfo | getFormatPrice }}</el-col
            >
          </el-row>
          <el-row
            style="
              height: 40px;
              line-height: 40px;
              border-bottom: 1px dashed #dcdcdc;
            "
          >
            <el-col :span="7" class="goodsAttr"
              ><label>货号: </label>{{ goodsInfo.ToySn }}</el-col
            >
            <el-col :span="7" class="goodsAttr"
              ><label>发布时间:</label>
              {{ goodsInfo.ITime | getDateFromDatetimeString }}</el-col
            >
            <el-col :span="7" class="goodsAttr"
              ><label
                >更新时间：{{
                  goodsInfo.CTime | getDateFromDatetimeString
                }}</label
              ></el-col
            >
            <el-col :span="3"></el-col>
          </el-row>
          <el-row
            style="
              /* margin-top: 14px; */
              height: 40px;
              line-height: 40px;
              border-bottom: 1px dashed #dcdcdc;
            "
          >
            <el-col :span="6" class="goodsSale"
              ><el-col :span="9">总销量: </el-col>
              <el-col :span="9" style="color: #ff2d4f">{{
                goodsInfo.sale_num
              }}</el-col>
              <el-col :span="2"
                ><div class="verticalSeparator">&nbsp;</div></el-col
              >
              <el-col :span="4">&nbsp;</el-col>
            </el-col>
            <el-col :span="6" class="goodsSale"
              ><el-col :span="9">展示量: </el-col>
              <el-col :span="9" style="color: #ff2d4f">{{
                goodsInfo.PHotNum
              }}</el-col>
              <el-col :span="2"
                ><div class="verticalSeparator">&nbsp;</div></el-col
              >
              <el-col :span="4">&nbsp;</el-col>
            </el-col>
            <el-col :span="6" class="goodsSale"
              ><el-col :span="11">上传/下载: </el-col>
              <el-col :span="8" style="color: #ff2d4f">{{
                goodsInfo.HotNum
              }}</el-col>
              <el-col :span="1"
                ><div class="verticalSeparator">&nbsp;</div></el-col
              >
              <el-col :span="4">&nbsp;</el-col>
            </el-col>
            <el-col :span="6" class="goodsSale"
              ><el-col :span="9">重量: </el-col>
              <el-col :span="15" style="color: #ff2d4f"
                >{{ goodsInfo.min_weight }}（g）起</el-col
              >
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0 10px 0">
            <el-col :span="24" class="contactQQ">
              <span
                ><span style="color: #767676">服务提醒：</span
                >如规格，价格有误，以商家实际价格为准，请联系客服修改</span
              ><br />
              <span v-if="goodsInfo && goodsInfo.message"
                ><span style="color: #767676">产品须知：</span
                >{{ goodsInfo.message }}</span
              >
            </el-col>
          </el-row>
          <el-row
            style="border-bottom: 1px dashed #dcdcdc"
            v-if="goodsInfo.skuFlag"
          >
            <el-col :span="3" class="skuLabel">选择颜色:</el-col>
            <el-col :span="21">
              <div
                v-for="(sku, i) in goodsInfo.specs"
                :key="i"
                :class="
                  i == activeSpecIndex ? 'goodsSpec is_active' : 'goodsSpec'
                "
                @click="changeGoodsSpec(i)"
              >
                <el-tooltip :content="sku.color" placement="top" effect="dark">
                  <span>{{ sku.color }}</span></el-tooltip
                >
              </div>
            </el-col>
          </el-row>
          <el-row v-if="goodsInfo.skuFlag">
            <el-col :span="24" v-for="(spec, i) in activeSpecList" :key="i">
              <el-col :span="3" class="skuLabel">{{
                i === 0 ? "选择尺码:" : "&nbsp;"
              }}</el-col>
              <el-col :span="21" class="skuList">
                <el-col
                  :span="4"
                  style="
                    font-weight: bold;
                    white-space: nowrap;
                    overflow-x: hidden;
                  "
                >
                  <el-tooltip
                    :content="spec.size"
                    placement="top"
                    effect="dark"
                  >
                    <span>{{
                      spec.size.length > 6
                        ? spec.size.substr(0, 6) + "..."
                        : spec.size
                    }}</span>
                  </el-tooltip>
                </el-col>
                <el-col
                  :span="8"
                  style="white-space: nowrap; overflow-x: hidden"
                  >sku:
                  <el-tooltip
                    :content="spec.goods_sn"
                    placement="top"
                    effect="dark"
                  >
                    <span>{{ spec.goods_sn }}</span>
                  </el-tooltip>
                  <el-tooltip
                    v-if="i === 0"
                    content="商家编码,每个商品每个尺码都有一个唯一的商家编码[SKU],方便商品搜索以及下单发货"
                    placement="bottom"
                    effect="dark"
                  >
                    <i
                      class="el-icon-question"
                      style="
                        color: #f95057;
                        position: relative;
                        font-size: 15px;
                      "
                    ></i>
                  </el-tooltip>
                </el-col>
                <el-col :span="3">&nbsp;{{ spec.weight }}(g)</el-col>
                <el-col :span="3">￥{{ spec.price }}</el-col>
                <el-col :span="6" style="margin-left: -10px">
                  <el-input-number
                    v-model="goodsNums[spec.id]"
                    @change="changeSpecNum"
                    :step="1"
                    size="small"
                    :min="0"
                    :max="spec.stock"
                  ></el-input-number>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
          <el-row class="skuDetail">
            <el-col :span="5" class="cartNum">{{ cart.num }} 件</el-col>
            <el-col :span="14" class="cartTotal">￥{{ cart.total }}</el-col>
            <el-col :span="5" class="cartList">
              <el-popover
                placement="top-start"
                width="550"
                offset="465"
                trigger="click"
              >
                <el-table :data="tableData" border>
                  <el-table-column
                    width="182"
                    property="color"
                    label="颜色"
                  ></el-table-column>
                  <el-table-column
                    width="182"
                    property="num"
                    label="数量"
                  ></el-table-column>
                  <el-table-column
                    width="184"
                    property="size"
                    label="尺寸"
                  ></el-table-column>
                </el-table>
                <div slot="reference">
                  已选清单<i class="el-icon-s-fold" id="icon-fold"></i>
                </div>
              </el-popover>
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <!-- 内容区底部按钮 -->
            <el-col :span="24" class="button">
              <el-popover
                placement="top-start"
                width="500"
                offset="0"
                trigger="hover"
              >
                <el-row style="padding: 20px 20px">
                  <el-col :span="4" style="font-weight: bold; color: #333"
                    >图片包</el-col
                  >
                  <el-col :span="16" style="color: #999"
                    >包括主图、颜色属性图、详情图、尺码表</el-col
                  >
                  <el-col :span="4"
                    ><el-button
                      type="danger"
                      size="mini"
                      plain
                      @click="zipDownload"
                      >立即下载</el-button
                    ></el-col
                  >
                </el-row>
                <el-row style="padding: 10px 20px 20px 20px">
                  <el-col :span="4" style="font-weight: bold; color: #333"
                    >短视频</el-col
                  >
                  <el-col :span="16" style="color: #999">包括主要短视频</el-col>
                  <el-col :span="4"
                    ><el-button
                      type="danger"
                      size="mini"
                      plain
                      @click="videoDownload"
                      >立即下载</el-button
                    ></el-col
                  >
                </el-row>
                <el-button
                  slot="reference"
                  class="download"
                  :style="
                    shopInfo.shipping_type == 3 && goodsInfo.ali_url
                      ? 'width: 120px'
                      : 'width: 160px'
                  "
                  >资源下载</el-button
                >
              </el-popover>
              <el-button
                v-if="shopInfo.shipping_type != 2"
                class="addCart"
                :style="
                  shopInfo.shipping_type == 3 && goodsInfo.ali_url
                    ? 'width: 120px'
                    : 'width: 160px'
                "
                @click="addShoppingCart"
                >加入购物车</el-button
              >
              <el-button
                v-if="shopInfo.shipping_type != 2"
                class="buyNow"
                :style="
                  shopInfo.shipping_type == 3 && goodsInfo.ali_url
                    ? 'width: 120px'
                    : 'width: 160px'
                "
                @click="addOrder"
                >立即购买</el-button
              >
              <el-button
                v-if="shopInfo.shipping_type != 1 && goodsInfo.ali_url"
                class="shipping"
                :style="
                  shopInfo.shipping_type == 3 && goodsInfo.ali_url
                    ? 'width: 120px'
                    : 'width: 160px'
                "
                @click="go1688"
                >商家直发</el-button
              >
            </el-col>
            <!-- 内容区底部按钮END -->
            <!-- 购物车弹窗 -->
            <el-dialog
              :visible.sync="cartDialogVisible"
              width="25%"
              top="36vh"
              custom-class="cartDialog"
            >
              <div class="dialogInfo">
                <div class="dialogTick">
                  <img :src="require('@/assets/imgs/tick.png')" />
                </div>
                <div class="dialogText">
                  <div class="textBig">已将商品添加到购物车</div>
                  <div class="textLittle">
                    您的购物车中已有<span>{{ getNum }}</span
                    >件商品
                  </div>
                </div>
                <div>
                  <router-link to="/shoppingCart">
                    <el-button
                      type="danger"
                      style="width: 154px; background: #ed3042"
                    >
                      去购物车结算
                      <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                  </router-link>
                </div>
              </div>
              <div class="dialogCarousel">
                <div class="carouselTitle">购买了该商品的用户还购买了：</div>
                <div class="carouselImages">
                  <el-carousel height="120px" indicator-position="none">
                    <el-carousel-item
                      v-for="(imageData, i) in groupDialogGoods"
                      :key="i"
                    >
                      <el-row>
                        <el-col
                          :span="8"
                          v-for="(imageFile, j) in imageData"
                          :key="j"
                        >
                          <router-link
                            :to="{
                              path: '/detail',
                              query: { id: imageFile.ID },
                            }"
                            target="_blank"
                          >
                            <img
                              :src="
                                $target +
                                '/UpLoad/' +
                                imageFile.Path +
                                '/' +
                                imageFile.FName
                              "
                              style="height: 120px; width: 120px"
                            />
                          </router-link>
                        </el-col>
                      </el-row>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </el-dialog>
          </el-row>
        </div>
        <!-- 中间规格区END -->
        <div class="shopInfo">
          <div class="infoHeader">
            <div>{{ shopInfo.CName }}</div>
            <span>{{ shopInfo.comeInYear }}年店</span>
          </div>
          <div class="infoRow">目前有货：{{ shopInfo.totalGoods }}款</div>
          <div class="infoRow">
            近期上新：{{
              shopInfo.updateNewTime
                ? shopInfo.updateNewTime.substr(0, 11)
                : "今日"
            }}
          </div>
          <div class="infoRow">一年销量：1001-5000件</div>
          <div class="infoRow">地址：{{ shopInfo.Addr }}</div>
          <div class="infoRow">
            品牌勋章：
            <div
              class="daifa"
              style="display: inline-block; margin: -5px 0 0 0"
            >
              代
            </div>
          </div>
          <div class="infoRow">QQ：{{ shopInfo.QQ }}</div>
          <div class="infoRow">微信：{{ shopInfo.Weixin }}</div>
          <div class="infoRow">电话：{{ shopInfo.Mobile }}</div>
          <div
            class="infoRow"
            style="padding-left: 45px"
            v-if="shopInfo.Mobile1 !== ''"
          >
            {{ shopInfo.Mobile1 }}
          </div>
          <div class="infoQrcode">
            <img :src="require('@/assets/imgs/qrcode1.png')" />
          </div>
          <div class="infoRemark">
            <span>微信</span>扫一扫， 获取厂家上新通知
          </div>
          <div class="infoBottom">
            <el-row>
              <el-col :span="12">
                <router-link
                  target="_blank"
                  :to="{ path: '/shop', query: { SID: shopInfo.ID } }"
                >
                  <el-button type="default" size="small" style="width: 84px"
                    >查看产品</el-button
                  ></router-link
                >
              </el-col>
              <el-col :span="12">
                <el-button type="default" size="small" style="width: 84px"
                  >收藏厂家</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 商品主要参数END -->
      <!-- 商品介绍 -->
      <div class="goodsDesc">
        <div class="moreGoods">
          <div class="moreHeader">推荐商品</div>
          <el-row
            v-for="(item, i) in moreGoodsData"
            :key="i"
            style="margin-top: 8px; border: 1px solid #f4f4f4"
          >
            <router-link
              :to="{
                path: '/detail',
                query: { id: item.ID },
              }"
              target="_blank"
            >
              <el-col :span="24" class="moreGoodsCover">
                <img :src="item.Picture" />
              </el-col>
              <el-col :span="24"
                ><div class="moreGoodsName">{{ item.PName }}</div></el-col
              >
              <el-col :span="12"
                ><div class="moreGoodsPrice">
                  ¥&nbsp;{{ item.Price ? item.Price : "见图" }}
                </div></el-col
              >
              <el-col :span="12">
                <div class="moreGoodsHit">
                  <i class="el-icon-star-off"></i>{{ item.PClickCount }}
                </div>
              </el-col>
            </router-link>
          </el-row>
        </div>
        <div class="goodsImageList">
          <el-tabs type="border-card">
            <el-tab-pane label="商品详情">
              <div style="text-align: center; margin: 0 auto">
                <el-image
                  v-for="(src, i) in allImageList"
                  style="max-width: 800px"
                  :key="i"
                  :src="src"
                  :preview-src-list="allImageList"
                ></el-image>
              </div>
            </el-tab-pane>
            <el-tab-pane label="销售记录">开发中</el-tab-pane>
            <el-tab-pane label="下载/上传记录">开发中</el-tab-pane>
            <el-tab-pane>
              <span slot="label">全网评价<span>(0)</span></span>
              开发中
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 商品介绍END -->
    </div>
    <!-- 主要内容END -->
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import ImageZoomer from "ht-image-zoomer";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

Vue.use(VideoPlayer);

// 重写localStorage原型，设置缓存时间
Storage.prototype.setExpire = (key, value, expire) => {
  let obj = {
    data: value,
    time: Date.now(),
    expire: expire,
  };
  //localStorage 设置的值不能为对象,转为json字符串
  localStorage.setItem(key, JSON.stringify(obj));
};

// 重写localStorage原型，获取定时缓存
Storage.prototype.getExpire = (key) => {
  let val = localStorage.getItem(key);
  if (!val) {
    return val;
  }
  val = JSON.parse(val);
  if (Date.now() - val.time > val.expire) {
    localStorage.removeItem(key);
    return null;
  }
  return val.data;
};

export default {
  name: "Detail",
  components: {
    ImageZoomer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      goodsNums: [], // 加减控件组绑定
      cart: {
        // 加减操作保存的数组
        num: 0,
        total: 0,
        list: [],
      },
      goodsId: 0,
      goodsInfo: {},
      shopInfo: {},
      goodsCover: "",
      video: "",
      videoGoodOption: {},
      activeImgIndex: 0, // swiper点击的图片序号
      activeSpecIndex: 0, // 点击的主规格序号
      activeSpecList: [], // 切换的主规格关联规格列表
      goodsPicture: [], // swiper图片列表
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 3,
        direction: "horizontal",
      },
      moreGoodsData: [],
      tableData: [], // 选购表格数据,computed计算属性不生效
      cartDialogVisible: false,
      dialogGoods: [],
    };
  },
  filters: {
    /**
     * 格式化时间
     */
    getDateFromDatetimeString(datetime) {
      if (datetime) {
        return datetime.substr(0, 10);
      }
    },
    /**
     * 格式化价格
     */
    getFormatPrice(goodsInfo) {
      if (goodsInfo.skuFlag) {
        return goodsInfo.price !== "0元起"
          ? goodsInfo.price
          : "请联系商家或客服开通代发货";
      } else {
        return goodsInfo.ali_url !== ""
          ? "请点击下方商家直发按钮进行下单"
          : "请联系商家或客服开通代发货";
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.goodsId = this.$route.query.id;
      this.getDetails(this.goodsId);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(["getNum"]),
    // ...mapActions(["setUser"]),

    /**
     * 详情图
     */
    allImageList: function () {
      if (this.goodsInfo.imageList) {
        const images = this.goodsInfo.imageList;
        return images.map((item) => {
          let preHost = this.$target + (item.IsEncry ? "/EnPic/" : "/UpLoad/");
          return preHost + item.Path + "/" + item.FName;
        });
      } else {
        return [];
      }
    },
    /**
     * 分组弹窗商品
     */
    groupDialogGoods: function () {
      let data = [];
      data.push(this.dialogGoods.slice(0, 3));
      data.push(this.dialogGoods.slice(3, 6));
      data.push(this.dialogGoods.slice(6, 9));
      return data;
    },
  },
  watch: {
    $route(newV) {
      if (newV.query.id) {
        this.goodsId = newV.query.id;
        this.getDetails(this.goodsId);
      }
    },
  },
  methods: {
    ...mapActions(["unshiftShoppingCart", "deleteShoppingCart", "setUser"]),

    /**
     * 获取商品详细信息(添加限时缓存)
     * @param {*} id
     */
    async getDetails(id) {
      // 获取商品详情
      const uid = this.$store.getters.getUser.id;
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/api/62442353b9c1b",
        { id: id, uid: uid ? uid : 0 }
      );
      if (data.data.specs && data.data.specs.length > 0) {
        data.data.specs.sort(this.sortByColor("color"));
      }
      this.goodsInfo = data.data;
      // 视频和初始封面图
      let swiperImages = [];
      if (this.goodsInfo.pics && this.goodsInfo.pics[0]) {
        swiperImages.push({
          type: this.goodsInfo.pics[0].type,
          file:
            this.goodsInfo.pics[0].type === 2
              ? this.goodsInfo.pics[0].poster
              : this.goodsInfo.pics[0].file,
          video:
            this.goodsInfo.pics[0].type === 2
              ? this.goodsInfo.pics[0].file
              : "",
        });
        this.video = swiperImages[0].video;
      }
      // 中间规格列表和左侧轮播图
      if (this.goodsInfo.specs && this.goodsInfo.specs[0]) {
        this.activeSpecList = this.goodsInfo.specs[0].list;
        this.goodsInfo.specs.map((item) => {
          const list = item.list;
          swiperImages.push({ type: 1, file: list[0].thumbnail, video: "" });
        });
        const specList = this.getGoodsSpecList();
        let numArr = this.goodsNums;
        for (let i in specList) {
          numArr[specList[i].id] = 0;
        }
        this.goodsNums = numArr;
      }
      this.goodsPicture = swiperImages;
      this.goodsCover = swiperImages[0] ? swiperImages[0].file : "";
      // 设置视频
      this.setVideoOption(swiperImages[0].file);
      // 获取关联商品
      this.getMoreGoods(id, this.goodsCover);
      // 获取商家信息
      this.getSupplyGoods(id, this.goodsInfo.SID);
    },
    /**
     * 设置视频参数
     * @param {*} poster
     */
    setVideoOption(poster) {
      if (this.video) {
        // this.video = this.video.replace('https://picture.toysmodel.cn:11443', 'https://video.toysmodel.cn')
        this.videoGoodOption = {
          autoplay: false,
          loop: true,
          preload: "auto",
          language: "zh-CN",
          aspectRatio: "1:1",
          fluid: true,
          notSupportedMessage: "此视频暂无法播放，请稍后再试",
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            fullscreenToggle: true, // 是否显示全屏按钮
          },
          sources: [
            {
              type: "video/mp4",
              src: this.video,
            },
          ],
          poster: poster,
        };
      }
    },
    /**
     * 获取关联商品(添加限时缓存)
     * @param {*} goods_id
     * @param {*} cover
     */
    async getMoreGoods(goods_id, cover) {
      if (cover) {
        if (!localStorage.getExpire("moreGoods" + goods_id)) {
          const { data } = await this.$axios.get(
            this.$apiBaseUrl +
              "/api/6086900689dc9?area_id=1&sp_id=" +
              this.goodsInfo.SID
          );
          localStorage.setExpire(
            "moreGoods" + goods_id,
            JSON.stringify(data.data),
            1000 * 1800
          );
        }
        this.moreGoodsData = JSON.parse(
          localStorage.getExpire("moreGoods" + goods_id)
        );
      }
    },
    /**
     * 获取商家信息(添加限时缓存)
     * @param {*} goods_id
     * @param {*} sid
     */
    async getSupplyGoods(goods_id, sid) {
      if (!localStorage.getExpire("supplyGoods" + goods_id)) {
        const { data } = await this.$axios.post(
          this.$newApi + "/api/rbh/supply/info",
          { sid: sid }
        );
        localStorage.setExpire(
          "supplyGoods" + goods_id,
          JSON.stringify(data.data),
          1000 * 3600
        );
      }
      this.shopInfo = JSON.parse(
        localStorage.getExpire("supplyGoods" + goods_id)
      );
      this.dialogGoods = this.shopInfo.goods;
    },
    /**
     * 切换商品封面图
     * @param {*} index
     */
    changeGoodsCover(index) {
      const cover = this.goodsPicture[index];
      this.goodsCover = cover.file;
      this.activeImgIndex = index;
    },
    /**
     * 切换商品规格
     * @param {*} index
     */
    changeGoodsSpec(index) {
      this.changeGoodsCover(index + 1);
      this.activeSpecIndex = index;
      this.activeSpecList = this.goodsInfo.specs[index].list;
    },
    /**
     * 下载图片压缩包
     */
    async zipDownload() {
      const { data } = await this.$axios.get(
        this.$newApi + "/api/rbh/goods/download?id=" + this.goodsId
      );
      if (data.code === 200) {
        window.open(data.data.zip, "_blank");
      } else {
        this.notifyError("下载异常，请稍后再试!");
      }
    },
    /**
     * 下载视频
     */
    videoDownload() {
      if (!this.video) {
        this.notifyError("当前产品无下载视频!");
      } else {
        window.open(this.video, "_blank");
      }
    },
    /**
     * 加减商品
     */
    changeSpecNum() {
      const specList = this.getGoodsSpecList();
      const numArr = this.goodsNums;
      let cartNum = 0;
      let cartTotal = 0;
      const cartList = [];
      for (const id in numArr) {
        if (numArr[id] && parseInt(numArr[id]) > 0) {
          const fliterSpecs = specList.filter((item) => {
            return item.id == id;
          });
          const spec = fliterSpecs[0];
          cartNum += parseInt(numArr[id]);
          cartTotal += parseInt(numArr[id]) * spec.price;
          spec.num = parseInt(numArr[id]);
          cartList[id] = spec;
        }
      }
      this.cart.list = cartList;
      this.cart.num = cartNum;
      this.cart.total = parseFloat(cartTotal).toFixed(2);
      this.setTableDataFromCart();
    },
    /**
     * 设置已选清单数据
     */
    setTableDataFromCart() {
      const data = this.cart.list;
      let tableData = [];
      for (let i in data) {
        if (data[i]) {
          tableData.push(data[i]);
        }
      }
      this.tableData = tableData;
    },
    /**
     * 获取商品全部规格数组
     */
    getGoodsSpecList() {
      const specs = this.goodsInfo.specs;
      let specList = [];
      for (let i in specs) {
        specList = specList.concat(specs[i].list);
      }
      return specList;
    },
    /**
     * 加入购物车
     */
    async addShoppingCart() {
      if (!this.$store.getters.getUser) {
        this.$store.dispatch("setShowLogin", true);
        return;
      }
      if (this.cart.num <= 0) {
        this.notifyError("请选择商品");
        return;
      }
      const { data } = await this.$axios.post(
        this.$newApi + "/api/rbh/cart/save",
        {
          uid: this.$store.getters.getUser.id,
          goods_id: this.goodsId,
          goods_spec: JSON.stringify(
            this.tableData.map((item) => {
              item.thumbnail = this.goodsCover;
              item.goods_num = item.num;
              return item;
            })
          ),
        }
      );
      if (data.code === 200) {
        this.tableData.map((item) => {
          this.deleteShoppingCart(item.goods_sn);
          this.unshiftShoppingCart(item);
          this.cartDialogVisible = true;
        });
      }
    },
    /**
     * 加入收藏
     */
    async addCollect() {
      if (!this.$store.getters.getUser) {
        this.$store.dispatch("setShowLogin", true);
        return;
      }
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/api/6087db4edf7ee",
        { uid: this.$store.getters.getUser.id, pid: this.goodsId }
      );
      this.notifySucceed(data.msg);
    },
    /**
     * 找同款
     */
    async searchTheSame() {
      this.notifySucceed("同款商品搜索中，请稍候");
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/api/62ab5261774a1",
        {
          url: this.goodsCover,
        }
      );
      if (data.code === 1) {
        this.$router.push({
          path: "/search",
          query: {
            data: JSON.stringify({
              list: data.data,
              totalCount: data.data.length,
            }),
          },
        });
      } else this.notifyError("暂无同款商品");
    },
    /**
     * 找同标题
     */
    searchPName() {
      let keyword = this.goodsInfo.PName;
      const pos = this.goodsInfo.PName.indexOf("+");
      if (pos !== -1) keyword = this.goodsInfo.PName.slice(0, pos);
      this.$router.push({ path: "/search", query: { keyword: keyword } });
    },
    /**
     * 创建订单
     */
    addOrder() {
      if (!this.$store.getters.getUser) {
        this.$store.dispatch("setShowLogin", true);
        return;
      }
      const specData = this.cart.list.filter((item) => {
        return item;
      });
      if (specData.length >= 1) {
        const orderData = specData.map((item) => {
          item.goodsName = this.goodsInfo.PName;
          item.sname = this.goodsInfo.SName;
          item.thumbnail = this.goodsCover;
          return item;
        });
        const order_no =
          Date.now().toString() + Math.floor(Math.random() * 100).toString();
        // console.log(order_no)
        localStorage.setItem(
          "orderGoods" + order_no,
          JSON.stringify(orderData)
        );
        const newRouter = this.$router.resolve({
          path: "/confirmOrder",
          query: { order_no: order_no },
        });
        window.open(newRouter.href, "_blank");
      } else {
        this.notifyError("请选择商品");
      }
    },
    /**
     * 商家直发
     */
    go1688() {
      window.open(this.goodsInfo.ali_url, "_blank");
    },
    /**
     * 同颜色排序
     */
    sortByColor(attr) {
      return function (a, b) {
        let attrA = a[attr];
        let attrB = b[attr];
        if (attrA.charCodeAt(0) === attrB.charCodeAt(0)) {
          return 0;
        } else {
          return attrA.charCodeAt(0) > attrB.charCodeAt(0) ? 1 : -1;
        }
      };
    },
  },
};
</script>
<style scoped>
@import "../assets/css/detail.css";
</style>
